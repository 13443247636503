import React from 'react';
import {Text, View} from 'react-native';

export default function NoInternetTrees() {
  return (
    <View>
      <Text>No Internet!</Text>
    </View>
  );
}
