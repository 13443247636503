export const en = {
  language: 'Language',
  balance: 'Balance (DAI)',
  projected: 'Projected (DAI)',
  plantedTrees: 'Planted Trees',
  withdraw: 'Withdraw',
  offlineMap: {
    title: 'Offline Map',
    downloadArea: 'Download this area?',
    permissionBlocked: 'Permission Blocked',
    downloaded: 'Map download completed',
    isDownloaded: 'The map was already downloaded',
    failed: 'Map download failed!',
    delete: 'Delete',
    savedAreas: 'Saved Areas',
    noOfflineArea: 'No offline area found',
    addArea: 'Add Area',
    viewAll: 'View Save Areas',
    download: 'Download this area',
    notSupported: 'Offline map is not available on web',
    downloadApplication: 'Download Ranger mobile application',
    sthWrongWithLocation:
      "We can't detect your Geo Position right now, please check your internet connection or try to restart the app",
  },
  getVerified: 'Get Verified',
  joiningOrganization: 'You are joining in organization:',
  joiningReferrer: 'You are referred by:',
  joinAndGetVerified: 'Join & Get Verified',
  help: 'Help',
  support: 'Support',
  activity: 'Activity',
  logout: 'Log Out',
  verified: 'Verified',
  notVerified: 'Not Verified',
  pending: 'Pending',
  update: 'Update',
  invite: {
    title: 'Invite',
    org: 'Organization',
    friend: 'Friend',
    copied: 'Invite link copied to your clipboard',
  },
  treeInventory: {
    title: 'Tree Inventory',
    updateTitle: 'Updated Offline',
    submitAll: 'Submit All',
    showProgress: 'Show Progress',
  },
  treeDetails: {
    cannotUpdate: {
      title: 'Cannot Update',
      details: 'Last update is pending',
      wait: 'Please wait for {{seconds}}',
    },
    gpsCoords: 'GPS Coordinates',
    funder: 'Funder',
    notFounded: 'Not Funded Yet!',
    lastUpdate: 'Last Update',
    born: 'Born',
    photo: 'Photo',
    photos: 'Photos',
    update: 'Update',
  },
  warning: 'Warning',
  notVerifiedTree: 'Not verified tree, please wait until being verified!',
  treeStatus: {
    Submitted: 'Submitted',
    Temp: 'Not Verified',
    OfflineCreate: 'Planted Offline',
    OfflineUpdate: 'Updated Offline',
  },
  cannotUpdateTree: "TreeSpec is empty, You can't update tree, please contact support.",
  cannotSubmitTree: "TreeSpec is empty, You can't plant tree, please contact support.",
  transactionFailed: {
    title: 'Transaction Failed!',
    tryAgain: 'Please try again, contact customer support if needed',
  },
  noInternet: 'No Internet',
  submitWhenOnline: 'You can submit this tree when you have fast and stable internet connection',
  offlineTreesSubmitted: 'All offline trees Submitted',
  tryAgain: 'Please try again!',
  cancel: 'Cancel',
  error: 'Error',
  plantFirstTree: 'Plant your first tree',
  plantTree: 'Plant tree',
  noOfflineTree: 'No offline {{type}} tree',
  offlineTrees: 'Offline {{type}} Trees',
  planted: 'planted',
  Planted: 'Planted',
  updated: 'updated',
  Updated: 'Updated',
  noAssignedOrVerified: 'There is no assigned or verified Tree',
  submittedTrees: 'Submitted Trees',
  notSubmittedTrees: 'Not Verified Trees',
  noPlantedTrees: "You haven't planted any trees yet",
  pendingVerification: 'Pending verification',
  addWalletAddress: 'Add wallet address',
  done: 'Done!',
  addPhone: 'Add phone',
  verifyPhone: 'Verify phone',
  addName: 'Add name',
  submitLocation: 'Submit location',
  openMap: 'Open Map',
  uploadIdCard: 'Upload ID card',
  joiningAsOrganization: 'Joining as organization owner?',
  verify: 'Verify',
  resend: 'Resend',
  sendCode: 'Send Code',
  unknownError: 'Unknown Error. Please contact customer support!',
  physicalLicense: 'ID card, passport or driving license',
  errors: {
    verificationCode: 'Code is wrong',
    phoneNumber: 'Invalid phone number',
    0: 'No Internet Connection!',
    INTERNET: 'No Internet Connection!',
    400: 'Bad Request',
    401: 'Unauthorized',
    403: 'Forbidden',
    404: 'Not Found',
    405: 'Method Not Allowed',
    500: 'Internal Server Error',
    error: 'Error!',
  },
  'verified!': 'Verified!',
  greenBlock: {
    notVerified: {
      title: 'Please verify your account',
      details: 'You must verify your account before joining a green block.',
    },
  },
  supplyCapReached: 'You reached your supply cap limit. Please contact customer support to plant more trees!',
  openCamera: 'Camera',
  openGallery: 'Gallery',
  submitTree: {
    journeyIssue: 'Photo, Location',
    treeSpecEmpty: "TreeSpec is empty, You can't update tree, please contact support.",
    treeDataNotLoaded: "TreeData not loaded, You can't plant tree, refresh the page and try again.",
    noWallet: {
      title: 'No wallet',
      details: 'Wallet not provided',
    },
    updated: 'The tree has been successfully updated',
    submitted: 'The tree has been successfully submitted',
    error: 'Error occurred',
    transactionFailed: "Transaction couldn't be completed",
    confirm: 'Please confirm to plant the tree',
    photoUpdated: 'Your photo is being uploaded',
    updateTree: 'Update tree',
    submitTree: 'Submit a new tree',
    takePhoto: 'Take a photo of the tree',
    treeLocation: 'Submit tree location',
    updateTreeLocation: 'Update Tree Location',
    uploadPhoto: 'Upload photo',
    signInWallet: 'Sign with wallet',
    singleTree: 'Single Tree',
    nursery: 'Nursery',
    focusedNursery: 'How many trees to plant?',
    nurseryCount: 'Nursery ({{count}} trees)',
    startToPlant: 'Start To Plant',
    models: 'Models',
    startToPlantNursery: 'Start To Plant {{count}} trees',
    update: 'Update',
    continue: 'Continue',
    nurserySubmitted: 'Nursery successfully submitted',
    nurserySubmitting: 'We are submitting your nursery',
    offlineLoading: 'We are submitting your offline planted trees. This is going to take a while!\nPlease be patient',
    offlineSubmittingNotCloseApp: "PLEASE DON'T CLOSE THE APPLICATION",
    hashCopied: 'Hash copied to clipboard',
    minimize: 'Minimize',
    noOfflineMapToContinue: {
      title: 'Ooops...',
      details:
        "You need to download map to continue planting tree when you are offline and you don't have access to the internet",
      continue: 'Get back online and',
      download: 'Download Offline Map',
      guide: 'Access anytime to the Offline Map Page to\nmanage your saved areas from,\nProfile > Offline Map',
    },
  },
  success: 'Success',
  failure: 'Failure',
  createWallet: {
    title: 'Create Wallet',
    setup: 'Set up wallet',
    recoveryPhrase: 'Recovery phrase',
    ethConnect: 'Please connect \n your Ethereum wallet!',
    connectToMagic: 'Connect Wallet',
    loginWithPhone: 'Log in / Sign up',
    or: 'OR',
    connect: 'Connect Wallet',
    why: {
      title: 'Why do I need that?',
      details: 'By connecting your Ethereum wallet, you will receive your rewards faster and safer',
      learnMore: 'Learn More',
    },
    failed: {
      title: 'Failed to login',
      details: 'Failed to authenticate via Torus. Please try again later.',
    },
    setupAccount: 'Set up account',
  },
  myProfile: {
    attention: 'Attention!',
    looseTree:
      'You have {{treesLength}} {{treeText}} planted in offline mode and {{treeThereText}} waiting to submit, if you logout now, you will loose your {{treeText}}!',
    logoutAndLoose: 'Logout & Loose',
    withdraw: {
      success: 'You successfully withdrew!',
    },
    lessBalance: 'Your balance is less than {{amount}}!',
    accuracy: 'Accuracy',
    offlineTreeAdd:
      'We are detected that you are offline.\nPlease submit this tree when you have fast and stable internet connection',
    offlineNurseryAdd:
      'We are detected that you are offline.\nPlease submit this nursery when you have fast and stable internet connection',
    copied: 'Copied to clipboard!',
  },
  sthWrong: 'Something went wrong!',
  verifyPending: {
    title: 'You’ll be verified soon!',
    tnx: 'Thank you!',
    verifying: 'You will receive a SMS when your identity is verified.',
    continue: 'Continue',
  },
  onBoarding: {
    first: {
      title: 'Connect to your Wallet',
      details: 'The new member will receive a SMS to join the Green Block in a few minutes.',
    },
    second: {
      title: 'Plant Trees or Support them',
      details: 'The new member will receive a SMS to join the Green Block in a few minutes.',
    },
    third: {
      title: 'Get Rewards',
      details: 'The new member will receive a SMS to join the Green Block in a few minutes.',
    },
  },
  netInfo: {
    error: 'Network Error!',
    details: 'You have no internet connection! \nYou can continue planting trees when you are offline',
    detailsWeb: 'You have no internet connection! \nYou can continue planting trees when you are online',
    filter: 'You have no internet connection! \nPlease check you network connection',
  },
  loginFailed: {
    title: 'Login Failed',
    message: 'Please try again, if you still have trouble, contact customer support!',
  },
  permission: {
    denied: 'Permission Denied!',
    required: 'Permissions required',
    camera: 'Camera permission is required',
  },
  ok: 'OK',
  retry: 'Retry',
  next: 'NEXT',
  submit: 'Submit',
  confirm: 'Confirm',
  loading: {
    ranger: "Ranger's App",
    by: 'by Treejer',
  },
  email: 'Email',
  invalidEmail: 'Your Email address is wrong',
  phoneNumber: 'Phone Number',
  settings: {
    title: 'Settings',
    useBiconomy: 'Transaction Fee Sponsorship',
    checkMetaData: 'Meta-data checking for tree submission',
    gsnDetails:
      'Treejer sponsors your transaction fees. If your transactions are being failed, you can switch off sponsorship. Please remember you need MATIC token in your wallet send transactions. You may ask your rural ambassador for more help.',
    ethBalance: 'Eth Balance:',
    maticBalance: 'Matic Balance:',
  },
  networks: {
    switchHeader: 'Network',
    selectNetwork: 'Select a network:',
    areYouSure: 'Are you SURE?',
    yes: 'Yes',
    no: 'No',
    testTitle: 'Test Networks',
    mainTitle: 'Main Networks',
    restartApp: 'The application will be restart and automatically log you out',
  },
  comingSoon: 'Coming Soon',
  tempLangEnglish: 'Temporary setting language to English',
  installApp: 'Install App',
  canDownload: 'You can also download the app',
  close: 'Close',
  googlePlay: 'Google Play',
  addToHomeScreen: {
    tap: 'Tap',
    homeScreen: 'then "Add to Home screen"',
  },
  webcam: {
    noCameraAccessible: 'No camera device accessible. Please connect your camera or try a different browser.',
    permissionDenied: 'Permission denied. Please refresh and give camera permission.',
    switchCamera:
      'It is not possible to switch camera to different one because there is only one video device accessible.',
    canvas: 'Canvas is not supported.',
  },
  offlineTreeSubmittingNotSupported: 'Offline tree submission is not supported on web',
  checkNetwork: 'Please check your network',
  backToProfile: 'Back to profile',
  rotate: 'Please rotate your phone',
  forceUpdate: {
    versionAvailable: 'New version is now available',
    updateContinue: 'Please update and then keep continue...',
    download: 'Download New Version',
  },
  organization: {
    invited: 'You invited to this organization:',
    accept: 'Accept',
    reject: 'Reject',
  },
  gps: {
    title: 'GPS',
    message: 'If you want plant a tree you should turn on your GPS',
  },
  inValidImage: {
    title: 'Image is not valid',
    message:
      "You can't upload this image because you didn't take this photo, or your GPS was off when you were taking that photo with the Camera.",
    hasNoLocation:
      "This image doesn't have location metadata please go to your camera settings and allow location permission. \nIf you still have trouble you can try to upload photo from gallery",
    longDistance: "You can't upload this image because distance between your location and image are too far.",
  },
  checkPermission: {
    permissions: {
      location: 'Location',
      camera: 'Camera',
      media: 'Media',
      GPS: 'GPS',
    },
    error: {
      deviceNotFound: 'Camera Device Not Found \n ({{message}})',
      siteSettings: 'Go to site settings',
      '1': 'Please allow location permission for this site in your browser site settings',
      '2': '{{message}}',
      '3': "we could'nt access to Geolocation at this time \n({{message}})",
      turnOnGPS: 'First you need to turn on your GPS',
      cantSupportGop: 'This devices can"t support geolocation',
      GPS: {
        '1Title': 'Grant location permission',
        '3Title': 'Geolocation information',
        '5Title': 'Location permission is not satisfied',
        '1': 'First you need to grant location permission. \n({{message}})',
        '2': "We can't access to GPS yet, please wait...",
        '3': 'Geolocation information was not obtained. \n({{message}})',
        '5': 'For plant a tree you need to turn on your GPS. \n({{message}})',
      },
      unknownError: 'Unknown error',
    },
    enabled: 'Enabled',
    turnOn: 'Turn ON!',
    grantNow: 'Grant Now!',
    toBeSure: 'We need to be sure, these permissions are granted.',
    granted: 'Granted',
    blocked: 'Blocked',
    checking: 'Checking...',
    wrong: "What's wrong?",
    checkingTitle: 'We are checking permissions to continue...',
    cantProceed: "We can't proceed",
    cantProceedDesc:
      'To plant a tree and nursery or upload a tree we need location, camera, and media permissions to acuurateyour activities and detect fraud.',
  },
  map: {
    newTree: {
      errTitle: 'You are not near to the location of your selected photo',
      errMessage: 'This location you are submitting must be near the selected photo location',
    },
    updateSingleTree: {
      errTitle: 'The selected photo location is not match with the last submitted location',
      errMessage: 'The location of the selected photo is not near to the submitted location of this {{plantType}}',
    },
  },
  tree: 'tree',
  nursery: 'nursery',
  magicWallet: {
    title: 'Wallet',
    daiBalance: 'DAI Balance:',
  },
  dai: 'DAI',
  transfer: {
    requesting: 'Lunching camera',
    noCameraPermission: 'Camera is not available',
    info: {
      title: 'Plant and Update to earn DAI',
      desc: 'By planting a tree, you will get awards due to raising and taking care of the planted tree. This awards are blocked on Treejer protocol and could be redeemed to your wallet connected to your account.\nYou can keep it on your account or move it to your personal wallet.',
    },
    redeem: 'Reedem from Treejer',
    treejerDescription: 'Withdrawable blocked DAI on Treejer Protocol',
    stablecoinDescription: 'Withdrawable DAI in Magic wallet',
    transactionHistory: 'Transaction History',
    date: 'Date',
    txHash: 'Tx Hash',
    id: '#',
    fee: 'Fee',
    form: {
      from: 'From',
      to: 'To',
      amount: 'Amount',
      max: 'Max',
      paste: 'Paste',
      toHolder: 'Recipient Address',
      amountHolder: 'DAI Amount',
      transfer: 'Transfer',
      history: 'History',
      confirm: {
        title: 'Transaction Details',
        alert: 'By clicking confirm you have no way back, double check the Recipient Address and lorem ipsum',
        cancel: 'Cancel',
        confirm: 'Confirm',
      },
    },
    success: {
      title: 'Transaction succeed',
    },
    formError: {
      length42: 'Recipient Address should be bigger than 41',
      length60: 'Recipient Address should be lower than 61',
      biggerThanDai: 'DAI Amount should be lower than your DAI balance',
      lowerThanZero: 'DAI Amount should be bigger than 0',
      required: '{{field}} field is required',
      shouldBeNumber: 'DAI Amount should be number',
      magicWallet: "You can't transfer DAI to your magic wallet, please try another one",
    },
    error: {
      contractsFailed: 'Get dai and ether balance failed',
      title: 'An error occurred',
      fee: "you can't submit this transaction",
      INVALID_ARGUMENT: 'Recipient Address is invalid',
    },
  },
  selectModels: {
    title: 'Select Models',
    tree: 'Plant Tree',
    nursery: 'Plant Nursery',
    choose: 'choose one of the models above',
    create: 'Create Model',
    createFirst: 'Create The First Model',
    focusedNursery: 'Nursery count?',
  },
  createModel: {
    title: 'Create Model',
    form: {
      country: 'Country',
      species: 'Species',
      price: 'Price',
      count: 'Count',
      create: 'Submit & Create',
    },
    placeholder: {
      country: 'Country...',
      species: 'Species...',
      price: 'Price...',
      count: 'Count...',
    },
    errors: {
      required: '{{ field }} field is required',
      number: '{{ field }} field must be number',
      min: 'price should be greater than 0',
    },
    createYours: 'Create your planting model',
    success: 'Model successfully created',
    failed: 'Something went wrong... try again',
  },
  supports: {
    chatOnline: 'Chat Online',
    discord: 'Join Discord',
    twitter: 'Follow Twitter',
    discussion: 'Treejer Discussion',
    chatHeader: 'Customer Support Chat',
    needHelp: 'Need Help',
  },
  activities: {
    matic: '{{amount}} Matic',
    eth: '{{amount}} ETH',
    dai: '{{amount}} DAI',
    new: 'New #{{tempId}}',
    all: 'All',
    verified: 'Verified',
    submitted: 'Submitted',
    updateSubmitted: 'Update Submitted',
    updateVerified: 'Update Verified',
    sent: 'Sent',
    withdraw: '${{ amount }} Redeemed',
    received: 'Received',
    claimed: 'Claimed',
    receipt: 'Receipt',
    showMore: 'Show more details',
    filters: 'Filters',
    PlanterJoined: 'Planter Joined',
    OrganizationJoined: 'Organization Joined',
    PlanterUpdated: 'Planter Updated',
    AcceptedByOrganization: 'Accepted By Organization',
    RejectedByOrganization: 'Rejected By Organization',
    OrganizationMemberShareUpdated: 'Member Share Updated',
    PlanterTotalClaimedUpdated: 'Total Claimed Updated',
    BalanceWithdrew: 'Withdraw',
    TreePlanted: 'Tree Planted',
    TreeUpdated: 'Tree Updated',
    TreeAssigned: 'Tree Assigned',
    AssignedTreePlanted: 'Assigned Tree Planted',
    AssignedTreeVerified: 'Assigned Tree Verified',
    AssignedTreeRejected: 'Assigned Tree Rejected',
    TreeVerified: 'Tree Verified',
    TreeRejected: 'Tree Rejected',
    TreeUpdatedVerified: 'Tree Updated Verified',
    TreeUpdateRejected: 'Tree Update Rejected',
    TransferOut: 'Sent',
    TransferIn: 'Received',
    empty: 'Empty',
  },
  mapMarking: {
    searchPlaceholder: 'Search for location...',
    lat: 'lat: {{lat}}',
    long: 'long: {{long}}',
    acc: 'accuracy: {{acc}}',
    NA: 'N/A',
    recent: 'Recent',
    empty: "We couldn't find the entered address",
    km: '{{km}} KM',
  },
};
